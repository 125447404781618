"use client";

import { useStore } from "@/lib/store";
import { motion, useDragControls, useMotionValue } from "framer-motion";
import { ChevronDown, GripHorizontal, Maximize2, Minimize2, Trash2 } from "lucide-react";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { debugStore } from "./debugPanelStore";
import "./styles.css";
const renderMessage = (msg: any, index: number) => {
  const getMessageClass = () => {
    switch (msg.type) {
      case "error":
        return "text-red-500 bg-red-100 dark:bg-red-900 dark:bg-opacity-20";
      case "warn":
        return "text-yellow-600 bg-yellow-100 dark:bg-yellow-900 dark:bg-opacity-20";
      default:
        return "text-green-600 bg-green-100 dark:bg-green-900 dark:bg-opacity-20";
    }
  };
  return <div key={index} className={`p-2 rounded mb-1 ${getMessageClass()}`} data-sentry-component="renderMessage" data-sentry-source-file="panel.tsx">
            <div className="font-mono text-xs text-gray-500 dark:text-gray-400 mb-1">
                {new Date(msg.timestamp).toLocaleString()}
            </div>
            <pre className="whitespace-pre-wrap break-words text-sm">
                {JSON.stringify(msg.data, null, 2)}
            </pre>
        </div>;
};
function useBodyRef() {
  const ref = useRef<HTMLBodyElement | null>(null);
  useLayoutEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      ref.current = body;
    }
  }, []);
  return ref;
}
export default function DebugPanel() {
  const data = useStore(debugStore);
  const [isMinimized, setMinimized] = useState(true);
  const logEndRef = useRef<HTMLDivElement | null>(null);
  const panelRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useBodyRef();
  const dragControls = useDragControls();
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const logContainerRef = useRef<HTMLDivElement | null>(null);
  const handleClear = () => debugStore.setValue({
    messages: []
  });
  const scrollToBottom = useCallback(() => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  }, []);
  if (process.env.NODE_ENV !== "development") return null;
  return <motion.div ref={panelRef} className={`debug-panel ${isMinimized ? "minimized" : ""} fixed z-[500] flex right-4 bottom-4 flex-col gap-2 bg-white dark:bg-gray-800 text-black dark:text-white rounded-lg shadow-lg border border-gray-200 dark:border-gray-700`} style={{
    maxHeight: isMinimized ? "40px" : "calc(100vh - 200px)",
    width: isMinimized ? "auto" : "400px",
    x,
    y
  }} drag dragControls={dragControls} dragMomentum={false} dragListener={false} dragConstraints={bodyRef} data-sentry-element="unknown" data-sentry-component="DebugPanel" data-sentry-source-file="panel.tsx">
            <div className="flex justify-between shrink-0 items-center cursor-move p-2 border-b border-gray-200 dark:border-gray-700">
                <motion.div className="flex items-center cursor-move gap-2 select-none flex-1" onPointerDown={e => dragControls.start(e)} data-sentry-element="unknown" data-sentry-source-file="panel.tsx">
                    <GripHorizontal className="size-4" data-sentry-element="GripHorizontal" data-sentry-source-file="panel.tsx" />
                    <h2 className="text-sm font-semibold">Debug Console</h2>
                </motion.div>
                <div className="flex gap-1">
                    <button onClick={handleClear} className="p-1 hover:text-red-500 transition-colors" title="Clear logs">
                        <Trash2 size={16} data-sentry-element="Trash2" data-sentry-source-file="panel.tsx" />
                    </button>
                    <button onClick={() => setMinimized(!isMinimized)} className="p-1 hover:text-blue-500 transition-colors" title={isMinimized ? "Maximize" : "Minimize"}>
                        {isMinimized ? <Maximize2 size={16} /> : <Minimize2 size={16} />}
                    </button>
                </div>
            </div>
            {!isMinimized && <div ref={logContainerRef} className="flex flex-col gap-1 overflow-auto p-2 *:shrink-0" style={{
      maxHeight: "calc(100vh - 160px)"
    }}>
                    {data.messages.map(renderMessage)}
                    {showScrollButton && <button onClick={scrollToBottom} className="absolute bottom-2 right-2 bg-gray-200 dark:bg-gray-700 rounded-full p-1 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors" title="Scroll to bottom">
                            <ChevronDown size={16} />
                        </button>}
                    <div ref={logEndRef} />
                </div>}
        </motion.div>;
}