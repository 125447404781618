import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/dashboard/app/_components/DebugPanel/panel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/dashboard/app/feedback-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/dashboard/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogPageview","PHProvider"] */ "/app/apps/dashboard/app/posthog-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/dashboard/lib/router-events/index.ts");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@14.2.10_@babel+core@7.25.2_supports-color@9.4.0__@opentelemetry+a_qzeygbfiqhfvsdwuptq34obxby/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@14.2.10_@babel+core@7.25.2_supports-color@9.4.0__@opentelemetry+a_qzeygbfiqhfvsdwuptq34obxby/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.10_@babel+core@7.25.2_supports-color@9.4.0__@opentelemetry+api@1.9.0_react-dom@18.3_klodhkv5ypqvk252cyydceezaq/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.10_@babel+core@7.25.2_supports-color@9.4.0__@opentelemetry+api@1.9.0_react-dom@18.3_klodhkv5ypqvk252cyydceezaq/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Cairo\",\"arguments\":[{\"subsets\":[\"arabic\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"variable\":\"--cairo-font\",\"adjustFontFallback\":false,\"display\":\"fallback\"}],\"variableName\":\"cairoFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.10_@babel+core@7.25.2_supports-color@9.4.0__@opentelemetry+api@1.9.0_react-dom@18.3_klodhkv5ypqvk252cyydceezaq/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"variable\":\"--opensans-font\",\"fallback\":[\"var(--cairo-font)\"],\"adjustFontFallback\":false,\"display\":\"swap\"}],\"variableName\":\"interFont\"}");
;
import(/* webpackMode: "eager" */ "/app/packages/hoose/dist-css/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientOnly"] */ "/app/packages/hoose/dist/headless.js");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/app/packages/hoose/dist/ui.js");
