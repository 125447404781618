"use client"
import { Store } from "@/lib/store";

const MAX_MESSAGES = 100;

class DebugStore extends Store<{
    messages: any[];
}> {
    constructor() {
        super({
            messages: [],
        });
    }
}

export const debugStore = new DebugStore();

export function debug(data: any) {
    if (process.env.NODE_ENV === "development") {
        debugStore.setValueImmer((prev) => {
            if (prev.messages.length >= MAX_MESSAGES) {
                prev.messages.shift();
            }
            prev.messages.push({
                data,
                type: "log",
                timestamp: new Date().toISOString(),
            });
        });
    }
}

export function debugError(data: any) {
    if (process.env.NODE_ENV === "development") {
        debugStore.setValueImmer((prev) => {
            prev.messages.push({
                data,
                type: "error",
                timestamp: new Date().toISOString(),
            });
        });
    }
}

export function debugWarn(data: any) {
    if (process.env.NODE_ENV === "development") {
        debugStore.setValueImmer((prev) => {
            prev.messages.push({
                data,
                type: "warn",
                timestamp: new Date().toISOString(),
            });
        });
    }
}
