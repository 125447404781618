"use client";

import React from "react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { IS_DEV } from "@/lib/env";
if (typeof window !== "undefined") {
  !IS_DEV && posthog.init("phc_CvwsivowixgR73UHfyMM8jkxcU6t1dpzBaCRB6yDVkG", {
    api_host: "/api/ph",
    capture_pageview: false,
    mask_all_text: false,
    capture_performance: true
  });
}
export function PostHogPageview() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    if (pathname) {
      let url = window.origin + pathname;
      if (searchParams && searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }
      posthog.capture("$pageview", {
        $current_url: url
      });
    }
  }, [pathname, searchParams]);
  return null;
}
export function PHProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-component="PHProvider" data-sentry-source-file="posthog-provider.tsx">{children}</PostHogProvider>;
}